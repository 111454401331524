<div class="row">
  <div class="col-md-12">
    <div class="card alertbox roboto-regular-font font-12">
      <div class="row card-header p-1">
        <div class="col-md-12 left-align">
          <div class="font-14 lh-24">
            <b>
              <span *ngIf="markersInfo?.length>0">{{ markersInfo[0].TrackerInfo?.TrackerId }}</span>
            </b>
            <button translate="no" type="button"
              class="notranslate float-right close btn-modal custom-btn-info-click click-infobox-close infobox-close-img"
              aria-label="Close">
              <span aria-hidden="true" class="custom-btn-info-click click-infobox-close infobox-close-img">
                <b class="custom-btn-info-click click-infobox-close infobox-close-img">&times;</b>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-1 pl-2 text-left {{isFromSensorChart?'alertboxbody-1':'alertboxbody'}} ">
        <div class="row pb-2" *ngIf="markersInfo?.length>0">
          <div class="col-md-12  mb-1">
            <span class="font-weight-bold"> ({{ markersInfo[0]?.Latitude }}, {{markersInfo[0]?.Longitude}}) </span>
          </div>
          <div *ngIf="!markersInfo[0]?.AddressGeoLoading; else addressLoader" class="col-md-12">
            <div *ngIf="markersInfo[0]?.TrackerInfo?.AddressGeo != ''; else viewCurrentLocation" class="value-div">
              <div class="value-div">
                <span class="font-weight-bold">{{ markersInfo[0]?.TrackerInfo?.AddressGeo }}</span>
              </div>
            </div>
            <ng-template #viewCurrentLocation>
              <button translate="no" type="button"
                class="notranslate btn btn-link custom-btn-info-click p-0 fn-8 font-weight-bold"
                i18n="@@link_show_address" id="{{'mapAddress_'+markersInfo[0]?.BreadCrumbId}}"
                attr.data-custom-dt="{{markersInfo[0]?.BreadCrumbId}}">
                Show Address
              </button>
            </ng-template>
          </div>
          <div *ngIf="markersInfo[0]?.Address" class="col-md-12">
            <div class="value-div">
              <span>{{ markersInfo[0]?.Address }}</span>
            </div>
            <div class="value-div">
              <span>{{ markersInfo[0]?.Address }}</span>
            </div>
          </div>
          <ng-template #addressLoader>
            <div class="col-md-12">
              <app-local-loading-indicator width="50%" height="28px">
              </app-local-loading-indicator>
            </div>
          </ng-template>
        </div>
        <div class="row" *ngFor="let markerInfo of markersInfo">
          <div class="w-100" *ngIf="markerInfo?.TrackerInfo?.LastReportedDateRange">
            <div class="line-break"></div>
            <div class="col-md-12 mb-1" *ngIf="markerInfo?.AlertInfo?.AlertId">
              <img src="{{markerInfo?.AlertInfo?.Image}}" width="30" />
              <b>
                <span>{{ markerInfo?.AlertInfo?.AlertTypeString}}</span>
              </b>
              <b *ngIf="markerInfo?.TrackerInfo?.TemperatureAvgString"> : {{
                markerInfo?.TrackerInfo?.TemperatureAvgString}}</b>
              <br>
            </div>
            <div class="col-md-12  mb-1">
              <span class="{{markerInfo?.AlertInfo?.AlertId?'ml-30':''}}"> {{
                markerInfo?.TrackerInfo?.LastReportedDateRange}}</span><span
                *ngIf="markerInfo?.TrackerInfo?.Count > 1 && isUserLocusAdminSupport && markerInfo?.TrackerInfo?.DateRangeInMinutes">{{"
                : " + markerInfo?.TrackerInfo?.DateRangeInMinutes + " "}}<span i18n="@@label-minutes">min</span></span>
            </div>
            <div class="col-md-12"
              *ngIf="markerInfo?.TrackerInfo?.TemperatureAvgString&&!(markerInfo?.labels?.length>0&&isFromSensorChart) && !markerInfo?.HideSensorValues&&!markerInfo?.AlertInfo?.AlertId">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}} d-inline-block">
                <span i18n="@@label_average_temperature"
                  *ngIf="markerInfo?.TrackerInfo?.Count>1;else normalTemperature">
                  Average Temperature</span>
                <ng-template #normalTemperature>
                  <span i18n="@@label_temperature">Temperature</span>
                </ng-template>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerInfo?.TrackerInfo?.TemperatureAvgString}}</span>
            </div>

            <div class="col-md-12"
              *ngIf="markerInfo?.TrackerInfo?.HumidityAvgString&&!(markerInfo?.labels?.length>0&&isFromSensorChart) && !markerInfo?.HideSensorValues">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@avg_humidity_header" *ngIf="markerInfo?.TrackerInfo?.Count>1;else normalHumidity">
                  Avg Humidity</span>
                <ng-template #normalHumidity>
                  <span i18n="@@label_humidity">Humidity</span>
                </ng-template>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerInfo?.TrackerInfo?.HumidityAvgString}}</span>
            </div>
            <div class="col-md-12"
              *ngIf="markerInfo?.TrackerInfo?.CarbonDioxideAvgString&&!(markerInfo?.labels?.length>0&&isFromSensorChart) && !markerInfo?.HideSensorValues">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@avg_carbon_header" *ngIf="markerInfo?.TrackerInfo?.Count>1;else normalCarbonDioxide">
                  Avg CO2</span>
                <ng-template #normalCarbonDioxide>
                  <span i18n="@@label_carbondioxide"> CO2</span>
                </ng-template>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerInfo?.TrackerInfo?.CarbonDioxideAvgString}}</span>
            </div>
            <div class="col-md-12"
              *ngIf="markerInfo?.TrackerInfo?.ProbeTemperatureAvgString&&!(markerInfo?.labels?.length>0&&isFromSensorChart) && !markerInfo?.HideSensorValues">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@avg_probe_header" *ngIf="markerInfo?.TrackerInfo?.Count>1;else normalProbe">
                  Avg Probe Temp</span>
                <ng-template #normalProbe>
                  <span i18n="@@label_probetemp">Probe Temp</span>
                </ng-template>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerInfo?.TrackerInfo?.ProbeTemperatureAvgString}}</span>
            </div>
            <div class="col-md-12" *ngIf="markerInfo?.TrackerInfo?.Count>1">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@label_count">Count</span>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerInfo?.TrackerInfo?.Count}}</span>
            </div>
            <ng-container *ngIf="markerInfo?.labels?.length>0&&isFromSensorChart">
              <div class="col-md-12" *ngFor="let k of markerInfo?.labels">
                <b *ngIf="k.value?.length>0">
                  <span>{{k.label}}</span>:&ensp;</b>
                <span>{{ k.value}}</span>
              </div>
            </ng-container>

            <div class="col-md-12" *ngIf="!(markerInfo?.labels?.length>0&&isFromSensorChart)">
              <ul class="navbar-nav mr-auto bttn-crumb-options"
                *ngIf="markerInfo?.BreadCrumbId && isAuthenticated && isLocusAdmin">
                <li class="nav-item">
                  <button translate="no" type="button"
                    class="notranslate btn btn-link py-1 px-0 fn-8 custom-btn-info-click click-show-hide-bread-crumb"
                    id="{{'mapHideCrumb_'+markerInfo?.BreadCrumbId}}" *ngIf=" isAuthenticated && !markerInfo.IsCrumbVisibilityUpdating &&
                  !markerInfo?.IsCrumbVisibilityUpdated" [disabled]="markerInfo?.IsHidden&&!showHiddenCrumbs"
                    attr.data-custom-dt="{{markerInfo?.BreadCrumbId}}">
                    {{markerInfo?.IsHidden?(showHiddenCrumbs?buttonShowBreadcrumbText:''):buttonHideBreadcrumbText}}
                  </button>
                  <label class="custom-label py-1" i18n="@@label_updated"
                    *ngIf="markerInfo?.IsCrumbVisibilityUpdated">Updated</label>
                  <app-local-loading-indicator width="50%" height="18px" *ngIf="markerInfo.IsCrumbVisibilityUpdating">
                  </app-local-loading-indicator>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ng-container *ngIf="markerCountInfo">
          <div class="row">
            <div class="col-md-12">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@label_accuracy">Accuracy</span>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerCountInfo?.CTTRadius ? getDataByUnits(markerCountInfo?.CTTRadius) : ""}}</span>
            </div>
            <div class="col-md-12">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@label_source">
                  Source</span>
              </b>
              <b>:&ensp;</b>
              <span *ngIf="markerCountInfo?.CellTowerCount && markerCountInfo?.WifiAPCount"
                i18n="@@label_wifi_cell">WiFi &
                Cell</span>
              <span *ngIf="!markerCountInfo?.CellTowerCount && markerCountInfo?.WifiAPCount"
                i18n="@@label_wifi">WiFi</span>
              <span *ngIf="markerCountInfo?.CellTowerCount && !markerCountInfo?.WifiAPCount"
                i18n="@@label_cell">Cell</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="markerCountInfo && isAdmin">
          <div class="row">
            <div class="col-md-12">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@label_wifi_count">
                  Wifi Count</span>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerCountInfo?.WifiAPCount ? markerCountInfo?.WifiAPCount : 0}}</span>
            </div>
            <div class="col-md-12">
              <b class="{{markerInfo?.TrackerInfo?.Count>1?'m-w-p-50':'m-w-p-30'}}  d-inline-block">
                <span i18n="@@label_cell_tower_count">
                  Cell Tower Count</span>
              </b>
              <b>:&ensp;</b>
              <span>{{ markerCountInfo?.CellTowerCount ? markerCountInfo?.CellTowerCount : 0}}</span>
            </div>
            <ul class="navbar-nav fixed-button bttn-crumb-options"
              *ngIf="markerCountInfo?.BreadCrumbId && isAuthenticated">
              <li class="nav-item"
                *ngIf="(!selectedRadiusBreadCrumbId && !(selectedRadiusBreadCrumbId === markerCountInfo?.BreadCrumbId)) && !isShowingLocationOrGeoFence">
                <button translate="no" type="button"
                  class="notranslate btn btn-link py-1 px-0 fn-8 custom-btn-info-click click-see-radius-crumb"
                  id="{{'mapRadiusCrumb_'+markerCountInfo?.BreadCrumbId}}"
                  *ngIf=" isAuthenticated && markerCountInfo.CTTRadius"
                  [disabled]="markerCountInfo?.IsHidden&&!showHiddenCrumbs"
                  attr.data-custom-dt="{{markerCountInfo?.BreadCrumbId}}" i18n="@@link_see_radius">
                  See Radius
                </button>
              </li>
              <li class="nav-item"
                *ngIf="(selectedRadiusBreadCrumbId === markerCountInfo?.BreadCrumbId) && !isShowingLocationOrGeoFence">
                <button translate="no" type="button"
                  class="notranslate btn btn-link py-1 px-0 fn-8 custom-btn-info-click click-hide-radius-crumb"
                  id="{{'mapRadiusCrumb_'+markerCountInfo?.BreadCrumbId}}"
                  *ngIf=" isAuthenticated && markerCountInfo.CTTRadius"
                  [disabled]="markerCountInfo?.IsHidden&&!showHiddenCrumbs"
                  attr.data-custom-dt="{{markerCountInfo?.BreadCrumbId}}" i18n="@@link_hide_radius">
                  Hide Radius
                </button>
              </li>
            </ul>
          </div>
        </ng-container>
        <ul class="navbar-nav fixed-button" *ngIf="!isFromSensorChart">
          <li class="nav-item">
            <button translate="no" type="button" *ngIf="isZoomInfoLevel && showInfoZoomOption"
              class="notranslate float-right close btn-modal btnZoomInfoLevel custom-btn-info-click click-infobox-zoom-in"
              aria-label="Close">
              <i class="fa fa-search-plus custom-btn-info-click click-infobox-zoom-in" aria-hidden="true"></i>
            </button>
            <button translate="no" type="button" *ngIf="!isZoomInfoLevel"
              class="notranslate float-right close btn-modal btnZoomInfoLevel custom-btn-info-click click-infobox-zoom-out"
              aria-label="Close">
              <i class="fa fa-search-minus custom-btn-info-click click-infobox-zoom-out" aria-hidden="true"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>