<div id="side-menu" class="hamberg-menu menu-sidebar sidebar collapse" [collapse]="hideSideMenu" [isAnimated]="true">
    <div class="hamberg-menu w-100">
        <div class="row hamberg-menu ">
            <div class="col-md-12 hamberg-menu ">
                <div class="pt-1 menu-bg-color hamberg-menu ">
                    <ng-template *ngIf="custContext$|async"></ng-template>
                    <ng-container *ngIf="showMainMenu; else showSubMenu">
                        <app-menu-link routeLink="/historical"
                            *ngIf="route.url!=='/historical'&&authState?.authenticated && authState?.canAccessOversight &&isCustomerSelected"
                            title="Historical" cssClass="menu-link" i18n-title="@@link_historical">
                        </app-menu-link>
                        <app-menu-link routeLink="/mapview" title="Hybrid View"
                            *ngIf="route.url!=='/mapview'&&authState?.authenticated && authState?.canAccessOversight &&isCustomerSelected"
                            cssClass="menu-link" i18n-title="@@link_hybridview">
                        </app-menu-link>
                        <app-menu-link [routeLink]="locale+'/signin'" routeLinkParam="trackerlookup"
                            *ngIf="route.url!=='/signin'" target="{{isUnAuthenticated?'':'_blank'}}"
                            cssClass="menu-link" title="Track Your Shipment" i18n-title="@@label_trackerlookup_title">
                        </app-menu-link>
                        <app-menu-link title="Management"
                            *ngIf="authState?.authenticated && authState?.canAccessOversight && isCustomerSelected"
                            cssClass="menu-link has-sub-menu" i18n-title="@@link_management" (click)="onShowSubMenu(1)">
                        </app-menu-link>
                        <!-- 22358 removed old scorecard option -->
                        <!-- <app-menu-link title="Admin" *ngIf="isLocusAdmin" cssClass="menu-link has-sub-menu"
                            i18n-title="@@link_admin" (click)="onShowSubMenu(5)">
                        </app-menu-link> -->
                        <app-menu-link title="Route Management" cssClass="menu-link has-sub-menu"
                            i18n-title="@@link_route_management" (click)="onShowSubMenu(2)"
                            *ngIf="showRouteBusinessRule&&authState?.authenticated && authState?.canAccessOversight &&isCustomerSelected">
                        </app-menu-link>

                        <app-menu-link title="Reports" cssClass="menu-link" i18n-title="@@link_reports"
                            routeLink="/reports"
                            *ngIf="authState?.authenticated && authState?.canAccessOversight && isCustomerSelected">
                        </app-menu-link>

                        <app-menu-link cssClass="menu-link" *ngIf="isPakSenseAuth$ | async" target="_blank"
                            url="{{pakSenseURL}}" title="Switch to Loggers" i18n-title="@@switch_to_paksense">
                        </app-menu-link>
                        <app-menu-link title="Training Center" cssClass="menu-link has-sub-menu"
                            *ngIf="authState?.authenticated&&isCustomerSelected" i18n-title="@@link_training_center"
                            (click)="onShowSubMenu(4)">
                        </app-menu-link>

                        <app-menu-link
                            *ngIf="showCompareTraxx&&authState?.authenticated && authState?.canAccessOversight &&isCustomerSelected"
                            cssClass="menu-link" routeLink="/compare-traxx" title="Compare Traxx"
                            i18n-title="@@link_compare_traxx">
                        </app-menu-link>
                        <app-menu-link
                            *ngIf="authState?.authenticated && authState?.canEditTripName && isCustomerSelected"
                            routeLink="/updateshipment" cssClass="menu-link" title="Update Shipment"
                            i18n-title="@@label_updateshipment_title">
                        </app-menu-link>
                        <a class="menu-link" *ngIf="isChineseAppDomain" i18n="@@link_toggle_domain_to_non_china"
                            target="_blank" href="{{oversightSecondaryURL}}">Switch to site for users outside of
                            China</a>
                        <a class="menu-link" *ngIf="!isChineseAppDomain" i18n="@@link_toggle_domain_to_china"
                            target="_blank" href="{{oversightSecondaryURL}}">Switch to site for users in China</a>
                        <!-- <span class="pl-2 version-number hamberg-menu">{{'Oversight '+version}}</span> -->

                        <app-menu-link cssClass="menu-link" title="Select Language" i18n-title="@@link_select_language"
                            (click)="onLocaleClick()">
                        </app-menu-link>
                        <app-menu-link title="API Management" cssClass="menu-link has-sub-menu"
                            *ngIf="(authState?.authenticated && isCustomerSelected)||isEDIAPIConsumer"
                            i18n-title="@@link_api_management" (click)="onShowSubMenu(6)">
                        </app-menu-link>
                        <a id="Create_Shipment_1" class="menu-link"
                            *ngIf="authState?.authenticated && isCustomerSelected"
                            (click)="onCalibrationStatementClick()"
                            i18n-title="@@label_nist_calibration_statements">NIST Calibration Statements</a>
                    </ng-container>
                    <ng-template class="sub-menu" #showSubMenu>
                        <a class="p-2 hamberg-menu" (click)="onShowMainMenu()">
                            <i class="fa fa-arrow-left pr-2 hamberg-menu" aria-hidden="true"></i>
                            <span class="hamberg-menu text-uppercase" i18n="@@link_main_menu">Main Menu</span></a>
                        <div class="custom-border-top"></div>
                        <ng-container [ngSwitch]="subMenu">
                            <div class="level-1" *ngSwitchCase=1>
                                <div class="menu-disabled hamberg-menu" i18n="@@link_location_management">Location
                                    Management
                                </div>
                                <div class="menu-tree">
                                    <app-menu-link cssClass="menu-link" title="Edit Locations"
                                        i18n-title="@@link_edit_locations" *ngIf="authState?.canAccessOversight"
                                        routeLink="/locations">
                                    </app-menu-link>
                                    <app-menu-link cssClass="menu-link" *ngIf="isLocusAdmin" title="Import Locations"
                                        routeLink="/importlocations" i18n-title="@@link_import_locations">
                                    </app-menu-link>
                                    <app-menu-link cssClass="menu-link" *ngIf="isLocusAdmin"
                                        [disabled]="isLocationExportInprogress$ | async" title="Export Locations"
                                        (click)="onLocationsExport()" i18n-title="@@link_export_locations">
                                    </app-menu-link>
                                </div>
                                <div class="custom-border-top"></div>
                                <div class="menu-tree">
                                    <div class="menu-disabled hamberg-menu" i18n="@@link_shipment_management">Shipment
                                        Management
                                    </div>
                                    <div class="menu-tree">
                                        <app-menu-link cssClass="menu-link" title="Edit Template"
                                            i18n-title="@@link_edit_template" *ngIf="authState?.canAccessOversight"
                                            routeLink="/templates">
                                        </app-menu-link>
                                    </div>
                                </div>
                                <div class="custom-border-top"></div>

                            </div>

                            <div class="level-1" *ngSwitchCase=2>
                                <div class="menu-disabled hamberg-menu" i18n="@@link_route_management">Route
                                    Management
                                </div>
                                <app-menu-link title="Route Editor" routeLink="/routeedit" cssClass="menu-link"
                                    i18n-title="@@link_route_editor">
                                </app-menu-link>
                                <app-menu-link title="Route Deviation" routeLink="/routedeviation" cssClass="menu-link"
                                    i18n-title="@@link_route_deviation">
                                </app-menu-link>
                                <!-- <app-menu-link title="Route Exception Report" cssClass="menu-link"
                                    i18n-title="@@link_route_exception_report">
                                </app-menu-link> -->
                            </div>
                            <div class="level-1" *ngSwitchCase=4>
                                <div class="menu-disabled hamberg-menu" i18n="@@link_training_center">Training Center
                                </div>
                                <app-menu-link options="Icons" title="Icon Info" cssClass="menu-link"
                                    i18n-title="@@link_icon_info">
                                </app-menu-link>
                                <a target="_blank" href="{{trainingURL}}" class="menu-link">
                                    <i class="fa fa-star-o mr-1"></i>
                                    <ng-container i18n="@@label_oversight_2_training">Oversight 2 Training
                                    </ng-container>
                                </a>
                                <ng-container *ngIf="isChickFilASupplier">
                                    <div class="custom-border-top"></div>
                                    <div class="menu-disabled hamberg-menu" i18n="@@label_supplier_traning_material">
                                        Mandated Programs
                                    </div>
                                    <div class="menu-tree">
                                        <a target="_blank" href="{{cfaSupplierTGURL}}"
                                            class="menu-link disable-menu-close"
                                            i18n="@@label_cfa_supplier_tg">Chick-fil-A
                                            Supplier Training Guide
                                        </a>
                                        <a target="_blank" href="{{cfaDistributionCenterTGURL}}"
                                            class="menu-link disable-menu-close"
                                            i18n="@@label_cfa_distribution_center_tg">Distribution Center Training Guide
                                        </a>
                                        <a target="_blank" href="{{cfaRedistributionTGURL}}"
                                            class="menu-link disable-menu-close"
                                            i18n="@@label_cfa_redistribution_tg">Redistribution Center Training Guide
                                        </a>
                                        <a target="_blank" href="{{cfaDirectTGURL}}"
                                            class="menu-link disable-menu-close" i18n="@@label_cfa_direct_tg">Direct One
                                            page
                                            instructions
                                        </a>
                                        <a target="_blank" href="{{cfaNondirectTGURL}}"
                                            class="menu-link disable-menu-close"
                                            i18n="@@label_cfa_nondirect_tg">Non-direct one
                                            page instructions
                                        </a>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="level-1" *ngSwitchCase=5>
                                <app-menu-link title="Manage Customer Dashboards" routeLink="/manage/customerdashboard"
                                    cssClass="menu-link" i18n-title="@@link_manage_customer_dashboard">
                                </app-menu-link>
                            </div>
                            <div class="level-1" *ngSwitchCase=6>
                                <app-menu-link title="API Sandbox" routeLink="/manage/ediapitest" cssClass="menu-link"
                                    i18n-title="@@link_api_sandbox">
                                </app-menu-link>
                            </div>
                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>